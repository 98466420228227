<script setup lang="ts">
import { ConfigProvider } from "radix-vue";

const useIdFunction = () => useId();
</script>

<template>
  <ConfigProvider :use-id="useIdFunction">
    <div
      :class="`
    diamond-crosshatch
    w-full
    flex
    flex-col
    min-h-[100dvh]
    bg-gray-50
  `"
    >
      <DashboardSiteHeader class="!bg-white" />
      <div class="flex flex-1">
        <main class="flex w-full overflow-clip">
          <slot />
        </main>
      </div>
      <NewFormModal />
      <Toast />
    </div>
  </ConfigProvider>
</template>
