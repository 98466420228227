<script setup lang="ts">
import UserNavigation from "../UserNavigation.vue";

const system = useSystemStore();
const { formModalOpen } = storeToRefs(system);

const headerLinkClasses = `
  flex
  font-semibold
  text-sm
  sm:text-base
  text-gray-500
  hover:text-pink-600
  -mb-7
  -mt-3.5
  pb-2
  border-b-4
  border-transparent
  items-center
`;
</script>

<template>
  <SiteHeaderContainer
    :full-width="true"
    class="!shadow-none !border-gray-300 h-[50px] !py-0"
  >
    <div class="justify-between flex items-center w-full">
      <div class="flex items-center gap-2 shrink-0">
        <NuxtLink class="flex items-center cursor-pointer" to="/dashboard">
          <Logo :mark-only="false" />
        </NuxtLink>
      </div>

      <div class="flex items-center gap-4 shrink">
        <UserNavigation>
          <Avatar />
        </UserNavigation>
        <Button
          size="sm"
          class="!bg-pink-600 hover:!bg-black !text-slate-100"
          @click="formModalOpen = true"
        >
          <Icon class="mr-2 h-3.5 w-3.5 sm:h-4 sm:w-4" name="lucide:edit" />
          <span class="text-xs sm:text-sm">New Form</span>
        </Button>
      </div>
    </div>
  </SiteHeaderContainer>
</template>
